import { createApi } from "@reduxjs/toolkit/query/react";
import axiosBaseQuery from "../axiosBaseQuery";

export const authApiSlice = createApi({
    reducerPath: "authApiSlice",
    baseQuery: axiosBaseQuery({
        transformResponse: (response) => response,
    }),
    endpoints: (builder) => ({
        logout: builder.mutation<unknown, void>({
            query: () => ({
                url: "/person/logout/",
                method: "POST",
            }),
        }),
    }),
});

export const { useLogoutMutation } = authApiSlice;
