import { DeleteSvg } from "@convin/components/svg";
import { ReactNode, useEffect, useState } from "react";
import { CrudButtonWrapper } from "./CrudButtonWrapper";
import DeleteDialog from "../Dialog/DeleteDialog";
import { moreOptionsChildSxProp } from "../reuseable/SxUtils";
import { Box, SxProps, Theme, useTheme } from "@mui/material";
import { showToast } from "@convin/utils/toast";
import { CreateUpdateToastSettings } from "@convin/config/toast.config";
import { isDefined } from "@convin/utils/helper/common.helper";
import { BoxOwnProps } from "@mui/system";

type DeleteButtonProps<T> = {
    onDelete: () => Promise<T>;
    callBack?: () => void;
    title?: string;
    message: string;
    isButton?: boolean;
    isLoading?: boolean;
    children?: ReactNode;
    sx?: SxProps<Theme>;
    buttonSx?: BoxOwnProps<Theme>;
    handleClose?: () => void;
    DeleteOptionComponent?: ReactNode;
    deleteMessage?: string;
    onOpenCallBack?: () => void;
};

const DeleteButton = <T,>({
    onDelete,
    callBack,
    title = "Delete Item",
    message,
    isButton,
    isLoading,
    children,
    sx,
    buttonSx = {},
    handleClose,
    DeleteOptionComponent,
    deleteMessage,
    onOpenCallBack,
}: DeleteButtonProps<T>): JSX.Element => {
    const theme = useTheme();
    const [open, setOpen] = useState<boolean>(false);
    const handleDelete = () => {
        onDelete().then((res) => {
            if (!isDefined(res)) return;
            if (!("error" in (res as { error?: unknown }))) {
                showToast({
                    ...CreateUpdateToastSettings,
                    message: deleteMessage ?? "Deleted Item Successfully",
                });
            }
            if (typeof callBack === "function") callBack();
            setOpen(false);
            if (typeof handleClose === "function") {
                handleClose();
            }
        });
    };

    useEffect(() => {
        if (open) {
            if (typeof onOpenCallBack === "function") onOpenCallBack();
        }
    }, [open]);

    return (
        <Box
            onClick={(e) => {
                e.stopPropagation();
            }}
        >
            {isButton ? (
                <CrudButtonWrapper sx={sx} onClick={() => setOpen(true)}>
                    <DeleteSvg />
                </CrudButtonWrapper>
            ) : isDefined(DeleteOptionComponent) ? (
                <Box
                    component={
                        isDefined(DeleteOptionComponent) ? "div" : "button"
                    }
                    onClick={() => setOpen(true)}
                >
                    {DeleteOptionComponent}
                </Box>
            ) : isButton ? (
                <CrudButtonWrapper sx={sx} onClick={() => setOpen(true)}>
                    <DeleteSvg />
                </CrudButtonWrapper>
            ) : (
                <Box
                    component="button"
                    onClick={() => setOpen(true)}
                    sx={{
                        ...moreOptionsChildSxProp(theme),
                        ...buttonSx,
                    }}
                >
                    <DeleteSvg sx={{ mr: 1 }} />
                    Delete
                </Box>
            )}

            <DeleteDialog
                open={open}
                setOpen={setOpen}
                onDelete={handleDelete}
                title={title}
                message={message}
                isLoading={isLoading}
            >
                {children}
            </DeleteDialog>
        </Box>
    );
};

export default DeleteButton;
