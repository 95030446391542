import { createApi } from "@reduxjs/toolkit/dist/query/react";
import axiosBaseQuery from "../axiosBaseQuery";
import { PaginationType } from "@convin/type/Common";
import { SamplingRule, SamplingRulePayload } from "@convin/type/Sampling.model";

export const samplingManagerApiSlice = createApi({
    reducerPath: "samplingManagerApiSlice",
    baseQuery: axiosBaseQuery({
        transformResponse: (response) => response,
    }),
    tagTypes: ["Sampling Rule"],
    endpoints: (builder) => ({
        getAllSamplingRules: builder.query<PaginationType<SamplingRule>, void>({
            query: () => "audit/sampling/list_all/",
            providesTags: [{ type: "Sampling Rule", id: "ALL" }],
        }),
        getSamplingRuleById: builder.query<SamplingRule, number>({
            query: (id) => `audit/sampling/${id}/`,
            providesTags: (_, err, id) =>
                !!err ? [] : [{ type: "Sampling Rule", id }],
        }),
        createSamplingRule: builder.mutation<unknown, SamplingRulePayload>({
            query: (body) => ({
                url: "audit/sampling/create/",
                method: "POST",
                body,
            }),
            // TODO: Remove invalidate after sampling payload update
            invalidatesTags: (_, err) =>
                !!err ? [] : [{ type: "Sampling Rule", id: "ALL" }],
        }),
        updateSamplingRule: builder.mutation<
            unknown,
            SamplingRulePayload & { id: number }
        >({
            query: ({ id, ...body }) => ({
                url: `audit/sampling/${id}/`,
                method: "PUT",
                body,
            }),
            // TODO: Remove invalidate after sampling payload update
            invalidatesTags: (_, err, { id }) =>
                !!err
                    ? []
                    : [
                          { type: "Sampling Rule", id },
                          { type: "Sampling Rule", id: "ALL" },
                      ],
        }),
        deleteSamplingRule: builder.mutation<void, number>({
            query: (id) => ({
                url: `audit/sampling/${id}/`,
                method: "DELETE",
            }),
            async onQueryStarted(id, { dispatch, queryFulfilled }) {
                await queryFulfilled;
                dispatch(
                    samplingManagerApiSlice.util.updateQueryData(
                        "getAllSamplingRules",
                        undefined,
                        (draft) =>
                            Object.assign(draft, {
                                ...draft,
                                results: draft.results.filter(
                                    (rule) => rule.id !== id
                                ),
                            })
                    )
                );
            },
        }),
    }),
});

export const {
    useGetAllSamplingRulesQuery,
    useGetSamplingRuleByIdQuery,
    useCreateSamplingRuleMutation,
    useUpdateSamplingRuleMutation,
    useDeleteSamplingRuleMutation,
} = samplingManagerApiSlice;
