import { createApi } from "@reduxjs/toolkit/dist/query/react";
import axiosBaseQuery from "../axiosBaseQuery";
import {
    AiFeebackQuestion,
    AiFeebackQuestionField,
    AiFeedbackSequencePayload,
} from "@convin/type/AiFeedback";
import { isDefined } from "@convin/utils/helper/common.helper";

export const aiFeedbackSlice = createApi({
    reducerPath: "aiFeedbackSlice",
    baseQuery: axiosBaseQuery({
        transformResponse: (response) => response,
    }),
    tagTypes: ["AiFeedback"],
    keepUnusedDataFor: 0,
    endpoints: (builder) => ({
        getAiQuestions: builder.query<AiFeebackQuestion[], void>({
            query: () => "/gpt/question/list/",
            providesTags: ["AiFeedback"],
        }),
        getCustomAiFeedbackQuesById: builder.query<AiFeebackQuestion, number>({
            query: (id) => `gpt/questions/${id}/`,
        }),
        createCustomAiFeedbackQuestion: builder.mutation<
            AiFeebackQuestion,
            Omit<AiFeebackQuestionField, "consider_from"> & {
                consider_from: number | null;
            }
        >({
            query: ({ ...payload }) => ({
                url: "gpt/questions/create/",
                method: "POST",
                body: payload,
            }),
            async onQueryStarted(_, { queryFulfilled, dispatch }) {
                try {
                    const { data } = await queryFulfilled;
                    dispatch(
                        aiFeedbackSlice.util.updateQueryData(
                            "getAiQuestions",
                            undefined,
                            (draft) => {
                                Object.assign(draft, [...draft, data]);
                            }
                        )
                    );
                } catch {
                    return;
                }
            },
        }),
        updateCustomAiFeedbackQuestion: builder.mutation<
            AiFeebackQuestion,
            Pick<AiFeebackQuestion, "id"> &
                Partial<Omit<AiFeebackQuestionField, "consider_from">>
        >({
            query: ({ id, ...payload }) => ({
                url: `gpt/questions/${id}/`,
                method: "PATCH",
                body: payload,
            }),
            invalidatesTags: (_, error) => (!!error ? [] : ["AiFeedback"]),
            async onQueryStarted(
                { id, is_disabled },
                { dispatch, queryFulfilled }
            ) {
                const patchResult = dispatch(
                    aiFeedbackSlice.util.updateQueryData(
                        "getAiQuestions",
                        undefined,
                        (draft) => {
                            Object.assign(
                                draft,
                                draft.map((e) =>
                                    e.id === id ? { ...e, is_disabled } : e
                                )
                            );
                        }
                    )
                );
                try {
                    const { data } = await queryFulfilled;
                    dispatch(
                        aiFeedbackSlice.util.updateQueryData(
                            "getCustomAiFeedbackQuesById",
                            id,
                            (draft) => {
                                Object.assign(draft, data);
                            }
                        )
                    );
                    dispatch(
                        aiFeedbackSlice.util.updateQueryData(
                            "getAiQuestions",
                            undefined,
                            (draft) => {
                                return [
                                    ...draft.map((item) => {
                                        if (item.id === id) return data;
                                        else return item;
                                    }),
                                ];
                            }
                        )
                    );
                } catch {
                    patchResult.undo();
                }
            },
        }),
        updateAiFeedbackSequence: builder.mutation<
            { status: boolean },
            AiFeedbackSequencePayload
        >({
            query: (payload) => ({
                url: "gpt/question/seq_no/bulk_update/",
                method: "PATCH",
                body: payload,
            }),
            invalidatesTags: (_, error) =>
                !isDefined(error) ? ["AiFeedback"] : [],
        }),
        deleteCustomAiFeedbackQuestion: builder.mutation<void, number>({
            query: (id) => ({
                url: `gpt/questions/${id}/`,
                method: "DELETE",
            }),
            async onQueryStarted(id, { dispatch, queryFulfilled }) {
                // const { data: deletedPhrase } = await queryFulfilled;
                const deleteResult = dispatch(
                    aiFeedbackSlice.util.updateQueryData(
                        "getAiQuestions",
                        undefined,
                        (draft) => draft.filter((e) => e.id !== id)
                    )
                );
                try {
                    await queryFulfilled;
                } catch {
                    deleteResult.undo();
                }
            },
        }),
    }),
});

export const {
    useGetCustomAiFeedbackQuesByIdQuery,
    useGetAiQuestionsQuery,
    useCreateCustomAiFeedbackQuestionMutation,
    useUpdateCustomAiFeedbackQuestionMutation,
    useUpdateAiFeedbackSequenceMutation,
    useDeleteCustomAiFeedbackQuestionMutation,
} = aiFeedbackSlice;
